import React from "react";
import { Product } from "@startapp/laira-user-ssr-api";
import { Box, LinkProps, Text, VStack } from "@chakra-ui/react";
import Image from "next/image";

import Strings from "resources/Strings";
import { currencyForBR } from "resources/format";
import { Link } from "components/general/Link";

interface IProps extends LinkProps {
	product: Product;
}

const cardWidth = { base: "100%", sm: "170px", lg: "210px" };
const cardHeight = { base: "160px", sm: "180px", lg: "210px" };

export const SimpleProductCard: React.FC<IProps> = ({ product, ...rest }) => {
	const image = product.images[0];

	return (
		<Link
			href={`/products/${product.slug}`}
			textDecoration="none"
			overflow="hidden"
			cursor="pointer"
			display="flex"
			borderColor="#586c6c"
			w={{ base: "135px", sm: "170px", lg: "210px"}}
			borderWidth="1px"
			flexDirection="column"
			height={{ base: "180px", lg: "280px" }}
			_hover={{
				shadow: "md",
			}}
			{...rest}
		>
			<Box width={cardWidth} height={cardHeight} overflow="hidden">
				<Image
					src={image.url}
					alt={`Image ${product.title}`}
					width={image.width}
					height={image.height}
					quality={30}
					layout="responsive"
				/>
			</Box>
			<VStack
				width={cardWidth}
				padding={3}
				alignItems="flex-start"
				mt={2}
			>
				<Text
					color="gray.500"
					height="100%"
					width="100%"
					isTruncated
					noOfLines={2}
					fontSize={{ base: "12px", lg: "md" }}
				>
					{product.title}
				</Text>
				<Text color="gray.600" fontSize={{ base: "12px", lg: "md" }}  fontWeight="500" mr={2}>
					{`${currencyForBR(product.price)}${Strings.components.productsCard.unitary}`}
				</Text>
			</VStack>
		</Link>
	);
};
